import { createStyles, createTheme, makeStyles } from '@material-ui/core';
import { MFE_PREFIX } from 'Global.styles';

import { theme } from '@bees/vision-tokens';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import palette from './themes/palette';
import typography from './themes/typography';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      body: {
        position: 'unset!important',
      },
      'div[class$="-variant-overlay"]': {
        top: '50%!important',
        left: '50%!important',
        bottom: 'auto!important',
        transform: 'translate3d(-50%, -50%, 0px)!important',
        minWidth: '28rem!important',
      },
      'header select~button': {
        pointerEvents: 'none',
        opacity: 0,
      },
      'main > header > div': {
        height: '128px',
      },
      [`body > span[data-radix-focus-guard]~.${MFE_PREFIX}-MuiAutocomplete-popper`]: {
        zIndex: '999999',
        marginTop: '-56px',
        pointerEvents: 'all',
      },
      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        /* display: none; <- Crashes Chrome on hover */
        '-webkit-appearance': 'none',
        margin: 0 /* <-- Apparently some margin are still there even though it's hidden */,
      },

      'input[type=number]': {
        '-moz-appearance': 'textfield' /* Firefox */,
      },

      'main > div > .admin-portal-delivery-window-beta-mfe-admin-portal-delivery-window-beta-mfe1':
        {
          position: 'initial!important',
          border: 'initial!important',
          overflow: 'initial!important',
          pointerEvents: 'initial!important',
          '& > .admin-portal-delivery-window-beta-mfe-admin-portal-delivery-window-beta-mfe3': {
            visibility: 'initial!important',
            maxWidth: 'initial!important',
            height: 'initial!important',
            pointerEvents: 'initial!important',
          },
        },
    },
  })
);

export const GlobalStyles = () => {
  useStyles();

  return null;
};

const defaultHubTheme = createTheme({
  palette,
  typography: typography as TypographyOptions,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          color: 'rgba(0, 0, 0, 0.87)',
          lineHeight: '1.43',
          letterSpacing: '0.01071em',
          backgroundColor: '#fafafa',
          fontSize: '0.875rem',
          fontFamily: 'Work Sans',

          '&::backdrop': {
            backgroundColor: '#fafafa',
          },
        },
      },
    },
    MuiOutlinedInput: {
      adornedStart: { paddingLeft: '16px' },
      root: {
        marginBottom: '16px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        lineHeight: '20px',
        marginBottom: '4px',
        width: '100%',
      },
    },
    MuiDialogActions: {
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: '16px',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '14px',
        padding: '12px 16px',
      },
    },
    MuiButton: {
      root: {
        padding: '7px 20px',
        fontWeight: 600,
        lineHeight: '24px',
        fontSize: '16px',
        textAlign: 'center',
      },
      text: {
        padding: '7px 20px',
      },
      outlinedPrimary: {
        borderColor: '#000000',
        boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      },
    },
    MuiSelect: {
      icon: {
        top: 'calc(50% - 12px)',
        color: '#000000',
        right: 7,
        position: 'absolute',
        pointerEvents: 'none',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #E0E0E0',
        color: palette.black,
        fontSize: '16px',
        letterSpacing: 0,
        lineHeight: '20px',
        padding: '8px 0 8px 16px',
        '&:first-child': {
          paddingLeft: '32px',
        },
        '&:last-child': {
          paddingRight: '32px',
          textAlign: 'right',
        },
      },
    },
    MuiMenuItem: {
      root: {
        letterSpacing: 0,
        lineHeight: '20px',
        color: palette.black,
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: palette.background.selectedButton,
        },
      },
      root: {
        '&.Mui-selected': {
          backgroundColor: palette.background.selectedItem,
        },
      },
    },
  },
});

export const defaultTheme = {
  ...defaultHubTheme,
  overrides: {
    ...defaultHubTheme?.overrides,
    MuiPickersDay: {
      dayDisabled: {
        color: '#ebebeb',
      },
    },
    MuiTooltip: {
      ...defaultHubTheme?.overrides?.MuiTooltip,
      tooltip: {
        ...defaultHubTheme?.overrides?.MuiTooltip?.tooltip,
        fontFamily: theme.fonts.paragraph,
        background: theme.colors.neutral100,
        color: `${theme.colors.interfaceForegroundLabelPrimary}!important`,
        fontSize: `${theme.fontSizes[2]}!important`,
      },
    },
    MuiIconButton: {
      ...defaultHubTheme?.overrides?.MuiIconButton,
      root: {
        ...defaultHubTheme?.overrides?.MuiIconButton?.root,
        color: theme.colors.neutral100,
      },
    },
  },
};
