import { DDCCode } from './DDC';
import PaginationInfo from './PaginationInfo';

export const ClientDaysOfWeekValues = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export type ClientDaysOfWeek = typeof ClientDaysOfWeekValues[number];

export enum ClientDayOfWeekType {
  noDelivery = 'NoDelivery',
  flex = 'Flex',
  free = 'Free',
}

export type ClientDocument = string;

export type ClientDeliveryFrequency = 7 | 14 | 28;

interface Client {
  id: string;
  ddcCode: DDCCode;
  code: number;
  name: string;
  document: ClientDocument;
  exception: boolean;
  country: string;
  vendorId?: string;
  daysOfWeek: ClientDayOfWeekSettings[];
  clickAndCollectActive: boolean;
  visitDate?: ClientDaysOfWeek;
  deliveryFrequency?: ClientDeliveryFrequency;
  baselineDate: string;
  accountId: string;
  audienceId?: string;
}

export enum ClientDaysOfWeekOrigins {
  Unknown = 'Unknown',
  VisitDay = 'VisitDay',
  NearbyPOC = 'NearbyPOC',
  CurrentDay = 'CurrentDay',
  FixedDays = 'FixedDays',
  Exception = 'Exception',
}

export type ClientDayOfWeekSettings =
  | {
      type: ClientDayOfWeekType.flex;
      dayOfWeek: ClientDaysOfWeek;
      minValue: number;
      addAmount: number;
      origin: ClientDaysOfWeekOrigins;
    }
  | {
      type: Exclude<ClientDayOfWeekType, ClientDayOfWeekType.flex>;
      dayOfWeek: ClientDaysOfWeek;
      minValue: number | null;
      addAmount: number | null;
      origin: ClientDaysOfWeekOrigins;
    };

export type ClientListFilterFields =
  | 'ddcCode'
  | 'ddcName'
  | 'clientCode'
  | 'clientDocument'
  | 'clientName';

export const DEFAULT_CLIENT_LIST_FILTER: ClientListFilterFields = 'clientDocument';
export interface ClientListSearch {
  field: ClientListFilterFields;
  value: string;
}

export interface ClientListFilter {
  search: ClientListSearch;
  pagination: PaginationInfo;
  selectedVendor: string;
}

/**
 * Type to make every part of the ClientListFilter partial without overlapping array types
 */
export interface ClientListFilterUpdate extends Partial<Omit<ClientListFilter, 'pagination'>> {
  pagination?: Partial<ClientListFilter['pagination']>;
}

export default Client;
