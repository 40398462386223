import { AutoblockSituationFilterOptions } from 'domains/AutoblockProgress';
import { ClientDayOfWeekType, ClientDaysOfWeekOrigins } from 'domains/Client';
import { ClientFileType } from 'domains/ClientFile';
import {
  AddAutoblockErrorCodes,
  AutoblockChannels,
  DdcGroup,
  StrategyType,
} from 'domains/DDCAutoblockAdd';
import { DDCAudienceFieldName, DDCsEditExceptionErrors } from 'domains/DDCEditData';
import { DeliveryWindowOrigin, DeliveryWindowsModalError } from 'domains/DeliveryWindow';
import { RuleDownloadFileSubType } from 'domains/RuleFile';
import MessageMap from 'i18n/i18n';

const es419: MessageMap = {
  TEST: {
    TEST_TRANSLATE: 'Translate {value}',
    TEST_LOREM: 'Lorem',
    TEST_IPSUM: 'Ipsum',
    TEST_DOLOR: 'Dolor',
  },
  DayOfWeek: {
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
  },
  DayOfWeekShort: {
    monday: 'Lun',
    tuesday: 'Mar',
    wednesday: 'Mie',
    thursday: 'Jue',
    friday: 'Vie',
    saturday: 'Sáb',
    sunday: 'Dom',
  },
  DayOfWeekRecurrency: {
    monday: 'Todos los lunes',
    tuesday: 'Todos los martes',
    wednesday: 'Todos los miércoles',
    thursday: 'Todos los jueves',
    friday: 'Todos los viernes',
    saturday: 'Todos los sábados',
    sunday: 'Todos los domingos',
  },
  DayOfWeekPreposition: {
    monday: 'en el lunes',
    tuesday: 'en el martes',
    wednesday: 'en el miércoles',
    thursday: 'en el jueves',
    friday: 'en el viernes',
    saturday: 'en el sábados',
    sunday: 'en el domingos',
  },
  General: {
    AND: 'y',
    CANCEL: 'Cancelar',
    CLEAR: 'Limpiar',
    GO_BACK: 'Regresar',
    YES: 'Si',
    NO: 'No',
    OF: 'de',
    NA: 'N/A',
    EMPTY: 'Vacío',
    ENABLED: 'Activado',
    DISABLED: 'Desactivado',
    DEFAULT_AUTOCOMPLETE_PLACEHOLDER_MULTIPLE: 'Seleccionar opciones',
    DEFAULT_EMPTY_LIST: 'No hay itens para mostrar',
    OPTIONAL: 'Opcional',
  },
  VendorSelect: {
    NO_VENDORS: 'No hay vendor establecido',
  },
  AppBar: {
    MENU_DDCS_LIST: 'Administrar DDCs',
    MENU_DELIVERY_WINDOW: 'Ventanas de entrega',
    MENU_IMPORTS_AND_EXPORTS: 'Importaciones y Exportaciones',
    MENU_AUTOBLOCK: 'Parámetros de disponibilidad de capacidad de entrega',
    MENU_AUDIENCES: 'Audiencias',
  },
  DataTable: {
    FOOTER_ROW_SELECTED_ONE: '{value} elemento seleccionado',
    FOOTER_ROW_SELECTED_MANY: '{value} elementos seleccionados',
    PAGINATION_OF: 'de',
    NO_ROWS: 'No hay itens para mostrar',
    ONE_ROW_SELECTED: 'seleccionado',
    MULTIPLE_ROWS_SELECTED: 'seleccionados',
    DESELECT_ROWS: 'deseleccionar',
    LOADING_MESSAGE: 'Cargando elementos...',
  },
  Pagination: {
    PAGE_SIZE_OPTION: 'Mostrar {value} registros por página',
    OF: 'de',
  },
  DropZone: {
    CONTAINER_TEXT: 'Arrastre y suelte un archivo CSV para subir o haga clic para buscar',
    FILE_LIMITE_EXCEED: 'Límite de archivos ({filesLimit}) excedido',
    PREVIEW: 'Archivos enviados:',
  },
  HexaDocumentUploader: {
    DROPZONE_PLACEHOLDER: 'Suelta aquí tu archivo',
    DROPZONE_ERROR_EMPTY: 'Por favor, selecciona un archivo válido',
    DROPZONE_ERROR_VALID_FILE:
      'El archivo debe tener el formato válido ({format}) y tener un tamaño máximo de {size}',
    DROPZONE_BUTTON: 'Buscar',
  },
  DateRange: {
    START_DATE: 'Fecha de inicio',
    END_DATE: 'Fecha final',
    DATE_FORMAT: 'dd/MM/yyyy',
  },
  TabNavigation: {
    DCCS_LIST: 'Administrar parámetros',
  },
  GenerateDeliveryWindowsButton: {
    BUTTON_TEXT: 'Reenviar Ventanas de Entrega',
    MODAL_TITLE: 'Atención!',
    MODAL_TEXT:
      'Se recomienda la generación de ventanas de entrega sólo para solucionar eventuales problemas. ¿Quieres proceder?',
    ERROR_MESSAGE: 'Error al solicitar generación de ventanas de entrega',
    SUCCESS_MESSAGE:
      'El proceso de generación de la ventana de entrega solicitada ahora está en progreso',
  },
  DDCsList: {
    TITLE: 'Administrar DDCs',
    BREADCRUMB: 'Administrar DDCs',
    DATAGRID_COLUMN_DDC_CODE: 'ID del DDC',
    DATAGRID_COLUMN_DDC_NAME: 'Nombre del DDC',
    DATAGRID_COLUMN_DDC_STATUS: 'Estado',
    DATAGRID_COLUMN_ACTIONS: 'Acciones',
    DATAGRID_CELL_ACTIONS_EDIT_TOOLTIP: 'Ver/Editar',
    DATAGRID_CELL_ACTIONS_HOLIDAY_TOOLTIP: 'Administrar feriados',
    DATAGRID_CELL_ACTIONS_RULES_TOOLTIP: 'Administrar reglas de inclusión/exclusión',
    DATAGRID_CELL_ACTIONS_CNC_TOOLTIP: 'Ver consumo Click & Collect',
    UNABLE_TO_LOAD_LIST: 'Error al cargar la lista de DDCs',
    EDIT_SELECTION_BUTTON: 'Editar selección',
    HOLIDAY_SELECTION_BUTTON: 'Administrar feriados',
    RULES_SELECTION_BUTTON: 'Administrar reglas de inclusión/exclusión',
    SEARCH_LABEL: 'Buscar por ID o Nombre',
    SEARCH_FILTER_LABEL: 'Filtro',
    FILTER_STATUS_ALL: 'Todos estados',
    FILTER_STATUS_ACTIVE: 'Estado activado',
    FILTER_STATUS_INACTIVE: 'Estado desactivado',
  },
  DDCsListClickAndCollectModal: {
    SUBTITLE: 'Click & Collect',
    DATAGRID_COLUMN_DATE: 'Fecha de recogida',
    DATAGRID_COLUMN_CAPACITY: 'Capacidad',
    DATAGRID_COLUMN_CONSUME: 'Utilización',
    DATEFORMAT: 'EEE, dd-MMM-yyyy',
    DATETIMEFORMAT: 'EEE, dd-MMM-yyyy, HH:mm',
    UNABLE_TO_LOAD_LIST: 'Error al cargar la lista Click & Collect',
    BUTTON_TOOLTIP_DISABLED: 'Estos parámetros Click & Collect están deshabilitados: {fields}',
    BUTTON_TOOLTIP_FIELD_ACTIVE: '"Día de la semana"',
    BUTTON_TOOLTIP_FIELD_FULL_ORDER_CAPACITY: '"Capacidad"',
  },
  DDCsAutoblockAddModal: {
    TITLE: 'Agregar un bloqueo automático',
    DDC_AUTOCOMPLETE_LABEL: 'Seleccionar centros de distribución',
    PERIOD_TITLE: 'Definir periodo',
    PERIOD_HINT:
      'Seleccione uno o varios días de la semana, o defina una fecha específica para aplicar el autobloqueo.',
    RADIO_DAYS_OF_WEEK_LABEL: 'Días recurrentes de la semana',
    RADIO_SPECIFIC_DATE_LABEL: 'Fecha específica',
    SPECIFIC_DATE_FORMAT: 'dd/MM/yyyy',
    LIMIT_TITLE: 'Definir número límite de visitas de DC',
    LIMIT_HINT: 'Si se alcanza el límite, la ventana de entrega se bloqueará.',
    LIMIT_INPUT_PLACEHOLDER: 'Ingresa el número de límite',
    CONFIRM_BUTTON: 'Agregar un bloqueo automático',
    TOAST_SUCCESS: 'Bloqueo automático agregado con éxito',
    TOAST_FAILURE: 'Error al crear Bloqueo automático',
    TOAST_MIXED: 'No se pudieron crear uno o más Bloqueo automático',
  },
  DDCsAutoblockRemoveModal: {
    MODAL_TITLE: '¿Eliminar bloqueo automático?',
    MODAL_TEXT:
      'la ventana de entrega dejará de seguir las definiciones de bloqueo automático. Si este autobloqueo ya se ha aplicado, se revertirá.',
    BUTTON_CONFIRM: 'Eliminar',
    BUTTON_CANCEL: 'Volver atrás',
    TOAST_SUCCESS: 'Bloqueo automático eliminado',
    TOAST_FAILURE: 'Error al eliminar bloqueo automático',
  },
  DDCsListHolidayConfirmModal: {
    TITLE: 'Gestión de feriados',
    CONFIRM_BUTTON: 'Continuar',
    CANCEL_BUTTON: 'Cancelar',
    UPPER_TEXT:
      'No es posible gestionar lost feriados de las Unidades a continuación, ya que no tienen la información de Ubicación:',
    LOWER_TEXT: '¿Quieres continuar?',
  },
  DDCsEdit: {
    TITLE: 'Editar datos',
    BREADCRUMB: 'Editar parámetros',
    SUBMIT_BUTTON: 'Confirmar parámetros',
    DDCS_LIST_TITLE: 'Unidades seleccionadas',
    DDCS_LIST_VIEW_MORE: 'Ver más',
    DDCS_LIST_HIDE: 'Ocultar',
    VISIT_DAY: 'Día de visita',
    HOLIDAY_DATE: 'Fecha de feriado',
    DELIVERY_DAY: 'Fecha de entrega',
    FORM_PARAMETERS_TITLE: 'Parámetros generales',
    FORM_GENERATE_DELIVERY_WINDOW: 'Generar Ventanas de Entrega',
    FORM_TIMEZONE: 'Zona horaria',
    FORM_DAYS_AFTER_VISIT_DAY: 'Regla automática - Días Corridos',
    FORM_DAYS_OF_WEEK: 'Regla automática - Días Laborables',
    FORM_HOLIDAY_DELIVERY_DAY: 'Día de entrega de feriado',
    FORM_HOLIDAY_DELIVERY_DAY_ZERO: 'No reemplace feriado',
    FORM_WINDOW_RANGE: 'Número de días para generar ventanas de entrega',
    FORM_EXPIRATION_DELIVERY_DAY_IS: 'Cuando la entrega está en',
    FORM_EXPIRATION_EXPIRATION_DAY_IS: 'Cuando el vencimiento está en',
    FORM_EXPIRATION_DAY: 'Día de expiración',
    FORM_EXPIRATION_TIME: 'Hora de expiración (local)',
    FORM_EXPIRATION_USE_FOR_ALL: 'Aplicar para todos',
    FORM_EXPIRATION_LABEL: 'Fecha y hora de caducidad:',
    FORM_WORKING_DAYS_LABEL: 'Días trabajados',
    FORM_WORKING_DAYS_HINT:
      'La fecha de vencimiento considerará estos días como válidos para el vencimiento',
    FORM_CLICK_AND_COLLECT_PARAMETERS_LABEL: 'Click & Collect - Parámetros Básicos',
    FORM_CLICK_AND_COLLECT_CONSUME_LABEL: 'Click & Collect - Días de la semana',
    FORM_CLICK_AND_COLLECT_ENABLED_LABEL: 'Click & Collect activado',
    FORM_CLICK_AND_COLLECT_RANGE_LABEL: 'Límite de días',
    FORM_CLICK_AND_COLLECT_EXPIRATION_LABEL: 'Tiempo de expiración (D-1)',
    FORM_CLICK_AND_COLLECT_DAY_ENABLED_LABEL: 'Día habilitado',
    FORM_CLICK_AND_COLLECT_DAY_START_LABEL: 'Inicio',
    FORM_CLICK_AND_COLLECT_DAY_END_LABEL: 'Fin',
    FORM_CLICK_AND_COLLECT_TIME_ERROR:
      'La hora de inicio debe ser menor que la hora de finalización',
    FORM_CLICK_AND_COLLECT_DISABLE_ERROR:
      'Una vez habilitado, Click and Collect debe permanecer habilitado durante al menos un día de la semana.\nPara deshabilitarlo por completo, será necesario cambiar la configuración de Click and Collect en los Clientes de esta Unidad',
    FORM_CLICK_AND_COLLECT_FULLORDERCAPACITY_ENABLED_LABEL: 'Administrar Capacidad Click & Collect',
    FORM_CLICK_AND_COLLECT_FULLORDERCAPACITY_VALUE_LABEL: 'Capacidad Click & Collect',
    UNABLE_TO_EDIT_CDDS: 'Error al editar unidades',
    UNABLE_TO_GET_DDC_INFO: 'Error al obtener datos de la unidad',
    SAVING_SUCCESS: 'Parámetros guardados con éxito',
    SAVING_ERROR: 'No se pudieron guardar una o más unidades.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.CncActiveDaysRequired.toUpperCase()}`]:
      'Debe tener al menos un día activo de Click and Collect.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidDayOfWeekSequence.toUpperCase()}`]:
      'Días de la semana no válidos.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidExpirationTime.toUpperCase()}`]:
      'Tiempo de caducidad no válido.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidFullOrderCapacityActive.toUpperCase()}`]:
      'Valores de capacidad de pedido total no válidos.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.InvalidFullOrderCapacityValue.toUpperCase()}`]:
      'Valores de capacidad de pedido total no válidos.',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.ErrorGetDdcBeesInformation.toUpperCase()}`]:
      'Error al recuperar la información de DC (BEES).',
    [`SAVING_ERROR_${DDCsEditExceptionErrors.OtherError.toUpperCase()}`]:
      'Por favor, inténtelo de nuevo más tarde.',
    FORM_GENERATE_DELIVERY_WINDOW_NULL: 'No cambiar',
    FORM_GENERATE_DELIVERY_WINDOW_ENABLED: 'Activado',
    FORM_GENERATE_DELIVERY_WINDOW_DISABLED: 'Desactivado',
    WORKING_DAYS_ALERT:
      'Las fechas de entrega y los días de vencimiento del fecha de entrega solo ocurren en los días seleccionados como laborables. Al cambiar este campo se volverán a calcular las fechas de entrega para todo el centro de distribución.',
    TOGGLE_BUTTON: 'Editar campo',
    TOGGLE_TUTORIAL_1:
      'Habilite o deshabilite los campos que desea cambiar o no haciendo clic en los botones “',
    TOGGLE_TUTORIAL_2:
      '”.\nLos campos habilitados sin valor se guardarán como vacíos. Los campos deshabilitados no cambiarán.',
  },
  DDCsEditV2: {
    TITLE: 'Editar configuración',
    BREADCRUMB: 'Editar configuración',
    DELIVERY_DAY: 'Día de entrega',
    SAVE_BUTTON: 'Guardar cambios',
    SELECTED_DDCS_TITLE: 'DDC seleccionados',
    SELECTED_DDCS_TEXT:
      'La configuración guardada se utilizará para crear ventanas de entrega para los DDC seleccionados.',
    BULK_WARNING:
      'Al editar varios DDCs, los campos editados se aplican a todos los DDCs seleccionados.',
    BULK_BUTTON_DISABLED: 'Editar sección',
    BULK_BUTTON_ENABLED: 'Cancelar edición',
    TAB_DELIVERY_WINDOWS: 'Ventanas de entrega',
    TAB_DDC_OPERATION: 'Operación del DDC',
    TAB_CLICK_AND_COLLECT: 'Click & Collect',
    PARAMETERS_TITLE: 'Parámetros',
    FIELD_GENERATE_DELIVERY_WINDOWS_LABEL: 'Generar ventanas de entrega',
    FIELD_WINDOW_RANGE_LABEL: 'Intervalo',
    FIELD_WINDOW_RANGE_HINT:
      'Los pedidos pueden entregarse en un plazo de 1 a 60 días después de la fecha de realización del pedido.',
    HOLIDAY_TITLE: 'Estrategia para días festivos',
    HOLIDAY_TEXT:
      'Establecer el comportamiento cuando un período de entrega caiga en un día festivo.',
    BUTTON_MANAGE_HOLIDAYS: 'Administrar días festivos',
    FIELD_HOLIDAY_DELIVERY_DAY: 'Comportamiento',
    FIELD_HOLIDAY_DELIVERY_DAY_ZERO: 'No sustituya los días festivos',
    HOLIDAY_DATE: 'Fecha de día festivo',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_ZERO:
      'Cuando el período de entrega cae en un día festivo, la fecha de entrega del pedido sigue siendo la misma.',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT:
      'Cuando el período de entrega cae en un día festivo, la fecha de entrega del pedido se actualiza a ',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_POSITIVE: ' día(s) antes del día festivo.',
    FIELD_HOLIDAY_DELIVERY_DAY_TEXT_NEGATIVE: ' día(s) después del día festivo.',
    AUTOMATIC_RULES_TITLE: 'Reglas automáticas',
    AUTOMATIC_RULES_TEXT:
      'Estas reglas solo se aplican cuando se selecciona la opción predeterminada DDC en la página Editar clientes.',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_LABEL: 'Por visitas de RN ',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_HINT:
      'Crear ventanas de entrega determinadas después de una visita de RN.',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_OPTION_SINGULAR: '1 día después',
    FIELD_AUTOMATIC_RULE_VISIT_DAY_OPTION_PLURAL: '{value} días después',
    FIELD_AUTOMATIC_RULE_DAY_OF_WEEK_LABEL: 'Días fijos',
    FIELD_AUTOMATIC_RULE_DAY_OF_WEEK_HINT:
      'Crear ventanas de entrega recurrentes dentro del intervalo para días específicos de la semana.',
    TIMEZONE_TITLE: 'Parámetros',
    FIELD_TIMEZONE_LABEL: 'Zona horaria',
    FIELD_TIMEZONE_HINT: 'Seleccionar la zona horaria de operaciones del DDC.',
    EXPIRATION_TITLE: 'Comportamiento de vencimiento predeterminado',
    EXPIRATION_TEXT:
      'Elegir el plazo predeterminado para realizar un pedido según la entrega y los días hábiles. Estas reglas pueden sobrescribirse mediante excepciones configuradas en la plataforma.',
    FIELD_WORKING_DAYS_LABEL: 'Días laborales',
    FIELD_WORKING_DAYS_HINT: 'Seleccionar los días de la semana en que funcionan los DDC.',
    EXPIRATION_DAYS_TITLE: 'Día de vencimiento',
    EXPIRATION_DAYS_TEXT:
      'Seleccionar la regla de cálculo para encontrar el día de vencimiento correspondiente a cada día de entrega.',
    FIELD_DELIVERY_DAY_LABEL: 'Día de entrega',
    FIELD_EXPIRATION_DAY_LABEL: 'Regla de cálculo',
    FIELD_CALCULATED_EXPIRATION_DAY_LABEL: 'Día de vencimiento',
    EXPIRATION_TIMES_TITLE: 'Plazos para la realización de pedidos',
    EXPIRATION_TIMES_TEXT:
      'Establecer el plazo para realizar un pedido correspondiente a cada día de vencimiento.',
    FIELD_EXPIRATION_TIME_LABEL: 'Plazo (hora del DDC)',
    CNC_PARAMETERS_TITLE: 'Parámetros',
    CNC_EXPIRATION_TIME_LABEL: 'Plazo para la realización de pedidos (D-1)',
    CNC_EXPIRATION_TIME_HINT: 'Fecha límite de pedido el día anterior.',
    CNC_RANGE_LABEL: 'Intervalo',
    CNC_RANGE_HINT:
      'Configurar el intervalo para Hacer clic y Recopilar en un plazo de 1 a 60 días.',
    CNC_FULL_ORDER_CAPACITY_ACTIVE_LABEL: 'Administrar capacidad',
    CNC_FULL_ORDER_CAPACITY_ACTIVE_HINT: 'Habilitar la gestión de capacidad de Click & Collect',
    CNC_FULL_ORDER_CAPACITY_VALUE_LABEL: 'Cantidad de pedidos',
    CNC_FULL_ORDER_CAPACITY_VALUE_HINT: 'Limitar la cantidad diaria de pedidos permitidos.',
    CNC_DAYS_OF_WEEK_TITLE: 'Configuración del intervalo de tiempo',
    CNC_DAYS_OF_WEEK_TEXT:
      'Establecer la hora de inicio y finalización de Hacer clic y Recopilar para cada día de la semana.',
    CNC_START_TIME_LABEL: 'Inicio',
    CNC_END_TIME_LABEL: 'Finalizar',
    CNC_DAY_OF_WEEK_DISABLED: 'El cobro revertido está desactivado ',
    CLICK_AND_COLLECT_CAPACITY_TITLE: 'Capacidad actual',
    IDENTIFIERS_TITLE: 'IDs de integración',
    FIELD_DDC_NAME_LABEL: 'Nombre del DDC',
    FIELD_DDC_NAME_PLACEHOLDER: 'Ingresar el nombre del DDC',
    FIELD_BEES_ID_LABEL: 'ID de BEES',
    FIELD_BEES_ID_HINT: 'Esta ID identifica el DDC dentro del ecosistema BEES.',
    FIELD_BEES_ID_PLACEHOLDER: 'Ingresa la ID de BEES',
    FIELD_CITY_CODE_LABEL: 'Código de ciudad',
    FIELD_CITY_CODE_HINT:
      'Igual que la ID de ciudad, se utiliza para la integración de días festivos en algunas zonas.',
    FIELD_CITY_CODE_PLACEHOLDER: 'Ingresa el código de ciudad',
    FIELD_FEDERATED_UNIT_LABEL: 'Estado',
    FIELD_FEDERATED_UNIT_HINT:
      'Igual que la ID de estado, se utiliza para la integración de días festivos en algunas zonas.',
    FIELD_FEDERATED_UNIT_PLACEHOLDER: 'Ingresa el estado',
    EDIT_SETTINGS_LABEL: 'Tipo de configuración',
    EIDT_SETTINGS_TOOLTIP:
      'La configuración predeterminada se aplica a los POCs dentro de los DDCs seleccionados. La configuración personalizada los sobrescribe para audiencias y DDCs específicos.',
    EDIT_SETTINGS_DEFAULT: 'Configuración predeterminada',
    EDIT_SETTINGS_CUSTOM: 'Configuración personalizada',
    CUSTOM_SETTINGS_TITLE: 'Configuración personalizada',
    CUSTOM_SETTINGS_TEXT:
      'La configuración personalizada sobrescribe la configuración predeterminada para los POCs dentro de las audiencias y DDCs.',
    CUSTOM_SETTINGS_NAME_TOOLTIP:
      'El nombre de la configuración es el mismo que el nombre de la audiencia seleccionada durante la creación de la configuración.',
    CUSTOM_SETTINGS_CREATE_BUTTON: 'Crear configuración personalizada',
    CUSTOM_SETTINGS_TABLE_EMPTY: 'No se ha creado ninguna configuración personalizada aún.',
    CUSTOM_SETTINGS_TABLE_NAME_COLUMN: 'Nombre de la configuración',
    CUSTOM_SETTINGS_TABLE_NAME_COLUMN_TOOLTIP:
      'El nombre de la configuración es el mismo que el nombre de la audiencia seleccionada durante la creación de la configuración.',
    CUSTOM_SETTINGS_TABLE_FIELDS_COLUMN: 'Opciones de configuración',
    [`CUSTOM_TABLE_FIELD_${DDCAudienceFieldName.expirationBehavior}`]:
      'Comportamiento de expiración',
    [`CUSTOM_TABLE_FIELD_${DDCAudienceFieldName.automaticRules}`]: 'Reglas automáticas',
    [`CUSTOM_TABLE_FIELD_${DDCAudienceFieldName.holidayStrategy}`]: 'Estrategia de vacaciones',
    [`CUSTOM_TABLE_FIELD_${DDCAudienceFieldName.windowRange}`]: 'Intervalo de ventanas',
    CUSTOM_SETTINGS_TABLE_CREATION_COLUMN: 'Guardado en',
    CUSTOM_SETTINGS_TABLE_CREATION_FORMAT: 'dd MMM, yyyy, HH:mm OOOO',
    CUSTOM_SETTINGS_TABLE_ACTION_COLUMN: 'Acciones',
    CUSTOM_SETTINGS_DELETE_MODAL_TITLE: '¿Eliminar configuraciones personalizadas?',
    CUSTOM_SETTINGS_DELETE_MODAL_TEXT:
      'Si elimina estas configuraciones, la perderá para todos los POCs dentro de las audiencias y DDCs.',
    CUSTOM_SETTINGS_DELETE_MODAL_DDC_LABEL: 'DDCs',
    CUSTOM_SETTINGS_DELETE_MODAL_SETTINGS_LABEL: 'Nombres de configuraciones',
    CUSTOM_SETTINGS_DELETE_MODAL_CONFIRM_BUTTON: 'Sí, eliminar',
    CUSTOM_SETTINGS_DELETE_MODAL_CANCEL_BUTTON: 'No, cancelar',
  },
  CustomSettingsEdit: {
    TITLE: 'Configuración personalizada',
    BREADCRUMB: 'Configuración personalizada',
    SELECTED_DDCS_TEXT:
      'Los ajustes guardados se usarán para crear configuraciones personalizadas para los DCs seleccionados.',
    AUDIENCE_TITLE: 'Audiencia',
    AUDIENCE_TEXT: 'Seleccione la audiencia de POCs para la cual se aplicarán estos ajustes.',
    AUDIENCE_HELP_MODAL_BUTTON: '¿Cómo crear una audiencia?',
    AUDIENCE_LAST_MODIFIED_TEXT: 'Esta audiencia fue modificada por última vez el',
    AUDIENCE_LAST_MODIFIED_FORMAT: 'EEE MMM dd yyyy',
    SETTINGS_OPTIONS_TITLE: 'Opciones de configuración',
    SETTINGS_OPTIONS_TEXT:
      'Establezca reglas automáticas para ventanas de entrega, comportamiento de expiración de pedidos y más.',
    WINDOW_RANGE_TITLE: 'Intervalo de ventana',
    WINDOW_RANGE_TEXT:
      'Los pedidos pueden ser entregados dentro de 1 a 60 días después de la fecha de realización del pedido.',
    HOLIDAY_TITLE: 'Estrategia de vacaciones',
    HOLIDAY_TEXT:
      'Establezca el comportamiento cuando una ventana de entrega caiga en un día festivo.',
    AUTOMATIC_RULES_TITLE: 'Reglas automáticas',
    AUTOMATIC_RULES_TEXT:
      'Estas reglas solo se aplican cuando se selecciona la opción predeterminada de DC en la página de Editar clientes.',
    EXPIRATION_TITLE: 'Comportamiento de expiración',
    EXPIRATION_TEXT:
      'Elija el plazo del pedido en función de los días de entrega y laborales. Estas reglas pueden ser sobrescritas por excepciones configuradas en la plataforma.',
    SAVING_SUCCES_TOAST: 'Configuraciones personalizadas guardadas con éxito.',
    SAVING_ERROR_TOAST: 'Error al guardar configuraciones personalizadas.',
    EDIT_SELECTED_DC_LABEL: 'DDC seleccionado',
    EDIT_CUSTOM_SETTINGS_NAME: 'Nombre de configuración',
    EDIT_CUSTOM_SETTINGS_NAME_TOOLTIP:
      'El nombre de la configuración es el mismo que el nombre de la audiencia seleccionada durante la creación de la configuración.',
    DELETE_SETTINGS_BUTTON: 'Eliminar configuración',
  },
  DDCsEditModalConfirmV2: {
    MODAL_TITLE: '¿Guardar cambios?',
    MODAL_TEXT: 'Se aplicarán las siguientes configuraciones a todos los DDC seleccionados.',
    BUTTON_CANCEL: 'No, volver',
    BUTTON_SUBMIT: 'Sí, guardar',
    FIELD_EMPTY: 'Vacío',
    DDCS_LIST: 'DDCs',
    VISIT_DAY: 'Visitas de RN',
    DELIVERY_DAY: 'Día de entrega',
    CLICK_AND_COLLECT: 'Click & collect',
    CLICK_AND_COLLECT_START_TIME: 'Inicio',
    CLICK_AND_COLLECT_END_TIME: 'Finalizar',
    DELIVERY_DAYS_AFTER_VISIT_DAY: 'Regla automática - Por visitas de RN ',
    DELIVERY_DAYS_OF_WEEK: 'Regla automática - Días fijos',
    HOLIDAY_DELIVERY_DAY: 'Estrategia para días festivos - Comportamiento',
    LEAVE_MODAL_TITLE: '¿Salir sin guardar?',
    LEAVE_MODAL_TEXT: 'Si te vas sin guardar, perderás todos los cambios.',
    LEAVE_MODAL_CANCEL: 'No, cancelar',
    LEAVE_MODAL_CONFIRM: 'Si, salir',
  },
  CustomSettingsModalConfirm: {
    MODAL_TITLE: '¿Guardar configuraciones personalizadas?',
    MODAL_TEXT:
      'Esto sobrescribirá las configuraciones predeterminadas para POCs en las audiencias y DDCs seleccionados.',
  },
  CustomSettingsHelpModal: {
    MODAL_TITLE: '¿Cómo crear una audiencia?',
    MODAL_TEXT_1: 'Debe acceder a la función de Audiencias y seguir estos pasos:',
    MODAL_LIST_1: '1. Seleccione Crear nueva audiencia.',
    MODAL_LIST_2: '2. Suba un archivo CSV con el grupo especificado de POCs.',
    MODAL_LIST_3: '3. Guarde la audiencia.',
    MODAL_TEXT_2:
      'Puede seleccionar la audiencia creada después de que se complete el procesamiento del archivo CSV.',
    CANCEL_BUTTON: 'Volver',
    CONFIRM_BUTTON: 'Ir a audiencias',
  },
  DDCsEditModalConfirm: {
    TITLE: 'Cambio de parámetro',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT: 'Se realizarán los siguientes cambios. ¿Desea continuar?',
    DDCS_LIST_TITLE: 'Unidades modificadas:',
    FIELDS_LIST_TITLE: 'Parámetros a guardar:',
    FIELD_EXPIRATION: 'Expiración',
    FIELD_EMPTY: 'Vacío',
  },
  DDCsAutoblock: {
    TABTITLE: 'Lista de parámetros de disponibilidad',
    BREADCRUMB: 'Parámetros de disponibilidad de capacidad de entrega',
    TITLE: 'Parámetros de disponibilidad de capacidad de entrega',
    SELECTED_DDCS_LABEL: 'DCs seleccionados',
    SEARCH_FIELD_PLACEHOLDER: 'Buscar por código o nombre de DC',
    ADD_AUTOBLOCK_BUTTON: 'Crear un parámetro de disponibilidad',
    DATAGRID_COLUMN_DDC_CODE: 'Código',
    DATAGRID_COLUMN_DDC_NAME: 'Centro de distribucion',
    DATAGRID_COLUMN_CREATED_AT: 'Creado en',
    DATAGRID_COLUMN_CREATED_BY: 'Creado por',
    DATAGRID_COLUMN_PERIOD: 'Período',
    DATAGRID_COLUMN_REASON: 'Motivo',
    DATAGRID_COLUMN_LIMIT: 'Número límite',
    DATAGRID_COLUMN_STRATEGY: 'Estrategias',
    DATAGRID_COLUMN_ACTIONS: 'Acciones',
    DATAGRID_COLUMN_ACTION_DELETE: 'Eliminar parámetro de disponibilidad',
    DATAGRID_COLUMN_ACTION_EDIT: 'Editar parámetro de disponibilidad',
    DATEFORMAT: 'dd-MMM-yyyy',
    DATAGRID_CELL_VISIT_LIMIT: '{value} visitas',
    DATAGRID_CELL_STRATEGIES_PLURAL: '{value} definidas',
    DATAGRID_CELL_STRATEGIES_SINGULAR: '{value} definida',
    UNABLE_TO_LOAD_LIST:
      'Error al cargar la lista de parámetros de disponibilidad. Por favor, inténtelo de nuevo más tarde.',
  },
  DDCsHoliday: {
    TITLE: 'Administrar feriados',
    BREADCRUMB: 'Administrar feriados',
    SUBMIT_BUTTON: 'Guardar configuración',
    DDCS_SIDELIST_TITLE: 'Unidades seleccionadas',
    UNABLE_TO_EDIT_CDDS: 'Error al guardar la configuración',
    TABLE_CELL_STATUS: 'Estatus',
    TABLE_CELL_ACTIONS: 'Acciones',
    TABLE_CELL_DDC_CODE: 'Código de unidad',
    TABLE_CELL_DDC_NAME: 'Unidad',
    TABLE_CELL_DATE: 'Fecha',
    TABLE_CELL_WORKED_HOLIDAY: 'Feriado trabajado',
    TABLE_CELL_TYPE: 'Tipo de Feriado',
    TABLE_NO_DATA: 'No se encontraron Feriados',
    FILTER_TITLE: 'Buscar:',
    FILTER_START_DATE: 'Fecha de inicio',
    FILTER_END_DATE: 'Fecha final',
    FILTER_SUBMIT: 'Buscar',
    FILTER_DATE_FORMAT: 'dd/MM/yyyy',
    ADD_NEW_TITLE: 'Agregar feriado:',
    ADD_NEW_DATE_FORMAT: 'dd/MM/yyyy',
    ADD_NEW_FIELD_DATE: 'Fecha del feriado',
    ADD_NEW_FIELD_DDC: 'Unidad',
    ADD_NEW_FIELD_DDC_ALL: 'Seleccionar todo',
    ADD_NEW_SUBMIT: 'Agregar',
    ADD_ERROR_DUPLICATED: 'No se agregaron uno o más feriados porque están duplicados',
    STATUS_EDITING: 'Feriado Editado',
    STATUS_DELETING: 'Feriad Marcado para la exclusión',
    STATUS_INSERTING: 'Nuevo Feriado',
    STATUS_PRISTINE: 'Feriado Salvado',
    TYPE_LOCAL: 'Regional',
    TYPE_STATE: 'Estatal',
    TYPE_NATIONAL: 'Nacional',
    ERROR_DUPLICATED_DATE: 'Ya hay un feriado con esta fecha para esta unidad',
    DELETE_LABEL: 'Marcar para eliminar',
    DELETE_LABEL_UNDO: 'Desmarcar para eliminar',
    DELETE_LABEL_NEW: 'Eliminar nuevo',
    UNABLE_TO_SAVE_ADD_EDIT: 'Error al insertar/editar feriados',
    UNABLE_TO_SAVE_DELETE: 'Error al eliminar feriados',
    SAVING_ERROR_EARLIER: 'Fecha del feriado anterior a la actual',
    SAVING_ERROR_ALREADYEXISTING: 'Feriado duplicado',
    SAVING_ERROR_MISSINGFEDERATEDUNIT: 'Feriado sin Estado federal',
    SAVING_ERROR_NOTFOUND: 'Feriado no encontrado',
    UNABLE_TO_SAVE_DATA:
      'Error al guardar feriados ingresados, editados y/o eliminados. Inténtalo de nuevo.',
    SAVING_ERROR_WARNING_1:
      'No se pudieron guardar un o más feriados. Ver detalles pasando el cursor sobre el “',
    SAVING_ERROR_WARNING_2: '” icono en las líneas resaltadas',
  },
  DDCsHolidayModalConfirm: {
    TITLE: 'Confirmar cambios de feriados',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT:
      'Esta acción no se puede deshacer y los días festivos se aplicarán a las ventanas de entrega. ¿Confirmar los cambios?',
  },
  DDCMissingFields: {
    WARNING_MESSAGE:
      'La Unidad no cuenta con información de {fields} para activar la ventana de entrega. La información de la unidad debe estar regularizada para su activación.',
    FIELD_CITYCODE: 'Localidad',
    FIELD_TIMEZONEID: 'Zona horaria',
    FIELD_GENERATEDELIVERYWINDOW: 'Generación de ventanas de entrega habilitada',
    FIELD_DAYSOFWEEKSETTINGS: 'Fecha/hora de Expiración',
    FIELD_ACTIVE: 'Unidad activa',
    DELIVERYDAYS_MESSAGE:
      'Esta unidad no tiene regla automática. No se generarán ventanas de entrega para clientes que no tengan configurada su propia semana de entrega.',
  },
  DDCsPagination: {
    ROWS_PER_PAGE_LABEL: 'por página',
  },
  DDCsRules: {
    TITLE: 'Editar reglas de inclusión/exclusión',
    BREADCRUMB: 'Editar reglas de inclusión/exclusión',
    OWNER_LIST_TITLE: 'Unidades seleccionadas',
    SUBMIT_BUTTON: 'Confirmar parámetros',
    OWNER_LIST_VIEW_MORE: 'Ver más',
    OWNER_LIST_HIDE: 'Ocultar',
  },
  ClientsList: {
    TITLE: 'Lista de clientes',
    BREADCRUMB: 'Ventanas de entrega',
    EDIT_SELECTION_BUTTON: 'Editar selección',
    RULES_SELECTION_BUTTON: 'Administrar reglas de inclusión/exclusión',
    UNABLE_TO_LOAD_LIST: 'Error al obtener la lista de clientes',
    DATAGRID_COLUMN_DDC_CODE: 'Código de DDC',
    DATAGRID_COLUMN_DOCUMENT: 'Vendor Account Id',
    DATAGRID_COLUMN_NAME: 'Nombre y código de POC',
    DATAGRID_COLUMN_EXCEPTION: 'Tipo de configuración',
    DATAGRID_EXCEPTION_POC_LEVEL: 'Nivel POC',
    DATAGRID_EXCEPTION_DEFAULT: 'Predeterminado',
    DATAGRID_EXCEPTION_CUSTOM: 'Personalizado',
    DATAGRID_ICON_FREE: 'Gratis\nOrigen: {origin}',
    DATAGRID_ICON_PAID:
      'Flexible\nGastos de envío: {addAmount}\nPedido mínimo: {minValue}\nOrigen: {origin}',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.Unknown}`]: 'Desconocido',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.VisitDay}`]: 'Día de la visita',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.NearbyPOC}`]: 'POC Cercana',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.CurrentDay}`]: 'Día actual',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.FixedDays}`]: 'Días fijos',
    [`DATAGRID_ORIGIN_${ClientDaysOfWeekOrigins.Exception}`]: 'Excepción',
    DATAGRID_CELL_ACTIONS_EDIT_TOOLTIP: 'Ver/Editar',
    DATAGRID_CELL_ACTIONS_RULES_TOOLTIP: 'Administrar reglas de inclusión/exclusión',
    DATAGRID_CELL_ACTIONS_DELIVERY_WINDOWS_TOOLTIP: 'Ver ventanas de entrega',
    LINK_UPLOAD_CSV: 'Subir/Descargar CSV de Clientes',
    SEARCH_LABEL: 'Buscar',
    SEARCH_FILTER_LABEL: 'Filtro',
    SEARCH_BUTTON: 'Buscar',
    SEARCH_FIELD_DDC_CODE: 'Cód. DDC',
    SEARCH_FIELD_DDC_NAME: 'Nombre DDC',
    SEARCH_FIELD_CLIENT_CODE: 'Cód. Cliente',
    SEARCH_FIELD_CLIENT_DOCUMENT: 'Vendor Account Id',
    SEARCH_FIELD_CLIENT_NAME: 'Nombre Cliente',
  },
  ViewDeliveryWindowsModal: {
    TITLE: 'Ventanas de entrega',
    CLOSE_BUTTON: 'Cerrar',
    TABLE_CELL_DELIVERY_WINDOW_ID: 'ID de ventana de entrega',
    TABLE_CELL_DATE: 'Fecha',
    TABLE_CELL_EXPIRATION_DATE: 'Fecha de expiración',
    TABLE_CELL_UPDATE_DATE: 'Fecha de actualización',
    TABLE_CELL_FLEXIBLE_DELIVERY: 'Entrega flexible',
    TABLE_NO_DATA: 'No se encontraron ventanas de entrega para este Cliente.',
    UNABLE_TO_GET_DATA: 'Error al obtener las ventanas de entrega',
  },
  ViewDeliveryWindowsModalV2: {
    SUBTITLE: 'Ventanas de entrega',
    TAB_ACTIVES: 'Activas',
    TAB_DELETED: 'Eliminadas',
    SEARCH_PLACEHOLDER: 'Buscar por ID',
    COLUMNA_ID: 'ID',
    COLUMN_DELIVERY_DATE: 'Fecha de entrega',
    COLUMN_DELIVERY_TYPE: 'Tipo de entrega',
    COLUMN_GENERATED_BY: 'Generado desde',
    COLUMN_DELETED_BY: 'Eliminado de',
    COLUMN_EXPIRATION_DATE: 'Fecha de vencimiento',
    COLUMN_UPDATE_DATE: 'Fecha de actualización',
    [`DELIVERY_TYPE_${ClientDayOfWeekType.flex}`]: 'Flex',
    [`DELIVERY_TYPE_${ClientDayOfWeekType.free}`]: 'Gratis',
    DELIVERY_TYPE_FLEX_MESSAGE: 'Tarifa: {addAmount} • Pedido mínimo: {minValue}',
    DELIVERY_TYPE_FREE_MESSAGE: 'Sin tarifa y pedido mínimo',
    [`ORIGIN_${DeliveryWindowOrigin.autoblock}`]: 'Capacidad de entrega',
    [`ORIGIN_${DeliveryWindowOrigin.daysOfWeek}`]: 'Día de la semana',
    [`ORIGIN_${DeliveryWindowOrigin.holiday}`]: 'Vacaciones',
    [`ORIGIN_${DeliveryWindowOrigin.inclusionRule}`]: 'Regla de inclusión',
    [`ORIGIN_${DeliveryWindowOrigin.exclusionRule}`]: 'Regla de exclusión',
    [`ORIGIN_${DeliveryWindowOrigin.removedDayOfWeek}`]: 'Día de la semana eliminado',
    [`ORIGIN_${DeliveryWindowOrigin.replacedDueHoliday}`]: 'Reemplazado por feriado',
    [`ORIGIN_${DeliveryWindowOrigin.holidayDeleted}`]: 'Vacaciones eliminadas',
    [`ORIGIN_${DeliveryWindowOrigin.ruleDeleted}`]: 'Regla eliminada',
    [`ORIGIN_${DeliveryWindowOrigin.ddcDisabled}`]: 'DC deshabilitado',
    [`ORIGIN_${DeliveryWindowOrigin.outOfWindowRange}`]: 'Fuera del alcance de ventanas',
    [`ORIGIN_${DeliveryWindowOrigin.integrationIssue}`]: 'Problema de integración',
    [`ORIGIN_${DeliveryWindowOrigin.outOfHolidayCalculation}`]: 'Vacaciones',
    [`ORIGIN_${DeliveryWindowOrigin.autoblockStrategyRemoved}`]: 'Capacidad de entrega eliminada',
    [`ORIGIN_${DeliveryWindowOrigin.autoblockDeleted}`]: 'Capacidad de entrega eliminada',
    [`ORIGIN_${DeliveryWindowOrigin.capacityStrategy}`]: 'Capacidad de entrega',
    [`ORIGIN_${DeliveryWindowOrigin.deliveryScheduledIdUpdated}`]: 'ScheduleId actualizado',
    [`ORIGIN_${DeliveryWindowOrigin.deliveryFrequencyChanged}`]: 'Frecuencia de entrega cambiada',
    [`ORIGIN_${DeliveryWindowOrigin.visitDateChanged}`]: 'Fecha de visita cambiada',
    [`ORIGIN_${DeliveryWindowOrigin.expiredDeliveryWindow}`]: 'Ventana de entrega vencida',
    [`ORIGIN_${DeliveryWindowOrigin.unknown}`]: 'Desconocido',
    HAS_AUTOBLOCK_CHANGE_TOOLTIP:
      'Fecha de entrega modificada por un parámetro de capacidad de entrega',
    DATEFORMAT: 'EEE, dd-MMM-yyyy',
    DATETIMEFORMAT: 'EEE, dd-MMM-yyyy, HH:mm',
    [`ERROR_${DeliveryWindowsModalError.accountIdNotFound}`]:
      'El contrato de este cliente no está vinculado a ninguna AccountId, no fue posible verificar las fechas de entrega',
    MINVALUE_DIVERGENCE_TOOLTIP:
      'Se detectó una discrepancia en el pedido mínimo. El valor debe ser {minValueDivergence}. Espere unos minutos para que se integren los datos o vuelva a enviar los plazos de entrega para este cliente.',
    ADDAMOUNT_DIVERGENCE_TOOLTIP:
      'Se detectó una discrepancia en la tarifa. El valor debe ser {addAmountDivergence}. Espere unos minutos para que se integren los datos o vuelva a enviar los plazos de entrega para este cliente.',
    MINVALUE_ADDAMOUNT_DIVERGENCE_TOOLTIP:
      'Se detectó una discrepancia entre la tarifa y el pedido mínimo. Los valores deben ser Tarifa {addAmountDivergence} y Pedido mínimo {minValueDivergence}. Espere unos minutos para que se integren los datos o vuelva a enviar los plazos de entrega para este cliente.',
    EXPIRATIONDATE_DIVERGENCE_TOOLTIP:
      'Se detectó una discrepancia en la fecha de vencimiento. El valor debe ser {expirationDateDivergence}. Espere unos minutos para que se integren los datos o vuelva a enviar los plazos de entrega para este cliente.',
  },
  ImportsAndExports: {
    TITLE: 'Importaciones y Exportaciones',
    BREADCRUMB: 'Importaciones y Exportaciones',
    TAB_CLIENTS: 'Clientes',
    TAB_AUTOMATED_EXCEPTIONS: 'Reglas',
    TAB_UPLOAD: 'Administrar',
    TAB_DOWNLOAD: 'Solicitudes de datos de paquetes',
    TAB_CLIENTS_UPLOAD: 'Subir Base de Clientes',
    TAB_CLIENTS_DOWNLOAD: 'Descargar Base de Clientes',
    TAB_AUTOMATED_EXCEPTIONS_UPLOAD: 'Subir Base de Reglas',
    TAB_AUTOMATED_EXCEPTIONS_DOWNLOAD: 'Descargar Base de Reglas',
    UPLOAD_FILTER_SEARCH_PLACEHOLDER: 'Buscar por nombre de archivo o usuario',
    DOWNLOAD_FILTER_SEARCH_PLACEHOLDER: 'Buscar por usuario',
    TITLE_CLIENTS_UPLOAD: 'Historial de Subidas de Base de Clientes',
    TITLE_CLIENTS_DOWNLOAD: 'Bases de clientes disponibles',
    TITLE_AUTOMATED_EXCEPTIONS_UPLOAD:
      'Historial de Subidas de Base de reglas de inclusión/exclusión',
    TITLE_AUTOMATED_EXCEPTIONS_DOWNLOAD: 'Bases de reglas disponibles',
    LINK_DOWNLOAD_DEFAULT_CLIENTS_FILE: 'Descargar plantilla',
    LINK_DOWNLOAD_DEFAULT_RULES_FILE: 'Descargar plantilla',
    LINK_REQUEST_CLIENTS_BASE: 'Solicitar Base de Clientes',
    LINK_REQUEST_AUTOMATED_EXCEPTIONS_BASE: 'Solicitar Base de Reglas',
    UNABLE_TO_UPLOAD_CSV: 'Error al enviar CSV.',
    UPLOAD_SUCCESS: '¡Archivo enviado con éxito!',
    REQUEST_CLIENTS_BASE_SUCCESS:
      'La base de clientes ha sido solicitada y pronto estará disponible para su descarga',
    REQUEST_CLIENTS_BASE_ERROR:
      'Aún se está procesando una solicitud. Por favor, espere unos minutos e intente de nuevo',
    REQUEST_AUTOMATED_EXCEPTIONS_BASE_SUCCESS:
      'La base de reglas ha sido solicitada y pronto estará disponible para su descarga',
    REQUEST_AUTOMATED_EXCEPTIONS_BASE_ERROR:
      'Aún se está procesando una solicitud. Por favor, espere unos minutos e intente de nuevo',
    HISTORY_DATAGRID_COLUMN_NAME: 'Archivo',
    HISTORY_DATAGRID_COLUMN_UPLOAD_DATE: 'Subido en',
    HISTORY_DATAGRID_COLUMN_LINES: 'Líneas',
    HISTORY_DATAGRID_COLUMN_LINES_SUCCESS: 'Éxito',
    HISTORY_DATAGRID_COLUMN_LINES_FAIL: 'Errores',
    HISTORY_DATAGRID_COLUMN_STATUS: 'Estatus',
    HISTORY_DATAGRID_COLUMN_USER: 'Subido por',
    HISTORY_DATAGRID_ACTION_DOWNLOAD: 'Descargar archivo',
    HISTORY_DATAGRID_ACTION_DOWNLOAD_FAIL: 'Descargar errores',
    UNABLE_TO_LOAD_HISTORY_LIST: 'Error al obtener el historial de cargas CSV',
    UNABLE_TO_LOAD_DOWNLOAD_BASE_LIST: 'Error al obtener los archivos para descargar',
    UNABLE_TO_DOWNLOAD_FILE: 'Error al descargar el archivo',
    UPLOAD_STATUS_awaitingProcessing: 'En cola',
    UPLOAD_STATUS_processing: 'Procesando',
    UPLOAD_STATUS_processed: 'Analizado',
    UPLOAD_STATUS_error: 'No se pudo procesar',
    UPLOAD_STATUS_invalidFile: 'Archivo inválido',
    UPLOAD_DATE_FORMAT: 'dd MMM, yyyy, hh:mm bb OOOO',
    UPLOAD_DATE_SENT: 'Enviado a las',
    DOWNLOAD_BASE_DATAGRID_COLUMN_REQUEST_DATE: 'Solicitado en',
    DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE: 'Tipo',
    [`DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE_${ClientFileType.downloadClients}`]: 'Base de Clientes',
    [`DOWNLOAD_BASE_DATAGRID_COLUMN_TYPE_${ClientFileType.downloadClientAudit}`]: 'Auditoría',
    DOWNLOAD_BASE_DATAGRID_COLUMN_STATUS: 'Estatus',
    DOWNLOAD_BASE_DATAGRID_COLUMN_USER: 'Solicitado por',
    DOWNLOAD_BASE_DATAGRID_ACTION_DOWNLOAD: 'Descargar archivo',
    DOWNLOAD_BASE_STATUS_awaitingProcessing: 'En cola',
    DOWNLOAD_BASE_STATUS_processing: 'Procesando',
    DOWNLOAD_BASE_STATUS_processed: 'Listo para Descargar',
    DOWNLOAD_BASE_STATUS_error: 'No se pudo procesar',
    DOWNLOAD_BASE_DATE_FORMAT: 'dd MMM, yyyy, hh:mm bb OOOO',
    DOWNLOAD_BASE_DATE_SENT: 'Enviado',
    UPLOAD_CLIENT_DATE_FIELDS_DENIED:
      'No tiene permiso para editar los campos "Fecha" y "Tipo de entrega". Estos campos se ignorarán cuando cargue un CSV.',
    UPLOAD_CLIENT_FLEX_FIELDS_DENIED:
      'No tiene permiso para editar los campos "Pedido minimo" y "Tarifa". Estos campos se ignorarán cuando cargue un CSV.',
    UPLOAD_CLIENT_CLICK_AND_COLLECT_FIELDS_DENIED:
      'No tiene permiso para editar campos "Click & Collect". Estos campos se ignorarán cuando cargue un CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_DATE_FIELDS_DENIED:
      'No tiene permiso para editar los campos "Fecha", "Fecha de expiración", y "Tipo de entrega". Estos campos se ignorarán cuando cargue un CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_FLEX_FIELDS_DENIED:
      'No tiene permiso para editar los campos "Pedido minimo" y "Tarifa". Estos campos se ignorarán cuando cargue un CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_DDC_DENIED:
      'No tiene permiso para editar reglas de Unidads. Estas reglas se ignorarán cuando cargue un CSV.',
    UPLOAD_AUTOMATED_EXCEPTIONS_CLIENT_DENIED:
      'No tiene permiso para editar reglas de Clientes. Estas reglas se ignorarán cuando cargue un CSV.',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_REQUEST_DATE: 'Fecha de solicitud',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE: 'Tipo',
    [`DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE_${RuleDownloadFileSubType.database}`]:
      'Base de Reglas',
    [`DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_TYPE_${RuleDownloadFileSubType.audit}`]:
      'Auditoría',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_STATUS: 'Estatus',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_COLUMN_USER: 'Usuario',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATAGRID_ACTION_DOWNLOAD: 'Descargar archivo',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_awaitingProcessing: 'En cola',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_processing: 'Procesando',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_processed: 'Listo para Descargar',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_STATUS_error: 'No se pudo procesar',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATE_FORMAT: 'dd/MM/yyyy hh:mm:ss bb',
    DOWNLOAD_AUTOMATED_EXCEPTIONS_DATE_SENT: 'Enviado',
    REQUEST_AUDIT_FILE_BUTTON: 'Descargar un único archivo CSV',
    REQUEST_AUDIT_FILE_SUCCESS:
      'El archivo ha sido solicitado y pronto estará disponible para descargar',
    REQUEST_AUDIT_FILE_ERROR: 'Error al solicitar el archivo',
    UPLOAD_MODAL_BUTTON_CLIENTS: 'Subir archivo de clientes',
    UPLOAD_MODAL_BUTTON_RULES: 'Cargar archivo de reglas',
    UPLOAD_MODAL_TITLE: 'Subir archivo CSV',
    UPLOAD_MODAL_DROPZONE_PLACEHOLDER: 'Suelta aquí tu archivo',
    UPLOAD_MODAL_DROPZONE_BUTTON: 'Buscar',
    UPLOAD_MODAL_CONFIRM: 'Subir',
  },
  RuleCSVUpload: {
    TITLE: 'Subir archivo de reglas',
    BREADCRUMB: 'Subir archivo de reglas',
    CARD_TITLE: 'Subir archivo',
    MAIN_LIST_TITLE: 'Descargue la plantilla CSV, revise las instrucciones y suba su archivo.',
    MAIN_LIST_ITEM_1: 'Los puntos y comas deben usarse solo como separadores de columnas.',
    MAIN_LIST_ITEM_2: 'Verifique ceros a la izquierda en los ID.',
    MAIN_LIST_ITEM_3: 'Guarde en formato CSV.',
    RULES_TARGET_LABEL: 'Objetivo de las reglas',
    RULES_TARGET_HINT: 'Seleccione el objetivo para sus reglas subidas.',
    RULES_TARGET_DDC: 'DDC',
    RULES_TARGET_CLIENT: 'Clientes',
    INSTRUCTIONS_TITLE: 'Revisar instrucciones del archivo',
    INSTRUCTIONS_ITEM_MOV: 'Minimum_Order_Value',
    INSTRUCTIONS_ITEM_MOV_1: 'Valor que debe alcanzarse para la entrega gratuita.',
    INSTRUCTIONS_ITEM_MOV_2: 'Use un punto como separador decimal.',
    INSTRUCTIONS_ITEM_FEE: 'Additional_Fee',
    INSTRUCTIONS_ITEM_FEE_1: 'Tarifa cobrada cuando no se alcanza el valor mínimo.',
    INSTRUCTIONS_ITEM_FEE_2: 'Use un punto como separador decimal.',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE: 'Delivery_Type',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE_1: 'Filtra los clientes DC que tendrán la regla aplicada.',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE_2:
      'Ingrese 0 o NO para clientes sin fecha de entrega, 1 o GRATIS para clientes con entrega gratuita y 2 o FLEX para clientes con entrega flexible.',
    INSTRUCTIONS_ITEM_DELIVERY_TYPE_3:
      'Si se deja en blanco, la regla se aplicará a todos los clientes del DC.',
    INSTRUCTIONS_ITEM_RULE_TYPE: 'Rule_Type',
    INSTRUCTIONS_ITEM_RULE_TYPE_1:
      'Una columna para agregar o eliminar una regla de inclusión o exclusión.',
    INSTRUCTIONS_ITEM_RULE_TYPE_2:
      'Ingrese 0 o EXCLUSION, 1 o INCLUSION_FREE, 2 o INCLUSION_FLEX, 3 o DELETE_RULE.',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE: 'Expiration_Date',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE_1: 'La fecha en que expira una regla.',
    INSTRUCTIONS_ITEM_EXPIRATION_DATE_2: 'El formato de la fecha es yyyy-mm-dd.',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME: 'Expiration_Time',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME_1: 'La hora en que expira una regla.',
    INSTRUCTIONS_ITEM_EXPIRATION_TIME_2: 'El formato de la hora es hh:mm.',
    TEMPLATE_DOWNLOAD_BUTTON: 'Descargar Plantilla',
    UPLOAD_HINT:
      'Arrastre y suelte un archivo desde su computadora o súbalo.\nSolo admitimos archivos CSV. Tamaño máximo del archivo: 50MB.',
    UPLOAD_BUTTON: 'Subir',
  },
  ClientCSVUpload: {
    TITLE: 'Subir archivo de clientes',
    BREADCRUMB: 'Subir archivo de clientes',
    CARD_TITLE: 'Subir archivo',
    MAIN_LIST_TITLE: 'Descargue la plantilla CSV, revise las instrucciones y cargue su archivo.',
    MAIN_LIST_ITEM_1: 'Los puntos y comas deben usarse solo como separadores de columnas.',
    MAIN_LIST_ITEM_2: 'Verifique los ceros a la izquierda en los IDs.',
    MAIN_LIST_ITEM_3: 'Guardar como formato CSV.',
    INSTRUCTIONS_TITLE: 'Revisar instrucciones del archivo',
    INSTRUCTIONS_ITEM_DAYS_WEEK: 'Days of the week',
    INSTRUCTIONS_ITEM_DAYS_WEEK_1: 'Indica qué días de la semana el cliente tendrá entrega.',
    INSTRUCTIONS_ITEM_DAYS_WEEK_2:
      'Ingrese 0 o NO para los días sin entrega, 1 o FREE para los días de entrega gratuita.',
    INSTRUCTIONS_ITEM_DAYS_WEEK_2_FLEX:
      'Ingrese 0 o NO para los días sin entrega, 1 o FREE para los días de entrega gratuita, y 2 o FLEX para los días de entrega flexible.',
    INSTRUCTIONS_ITEM_MOV: 'Minimum_Order_Value',
    INSTRUCTIONS_ITEM_MOV_1: 'Valor que debe alcanzarse para la entrega gratuita.',
    INSTRUCTIONS_ITEM_MOV_2: 'Use un punto como separador decimal.',
    INSTRUCTIONS_ITEM_FEE: 'Additional_Fee',
    INSTRUCTIONS_ITEM_FEE_1: 'Tarifa cobrada cuando no se alcanza el valor mínimo.',
    INSTRUCTIONS_ITEM_FEE_2: 'Use un punto como separador decimal.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE: 'Reference_Date',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_1: 'Referencia utilizada para calcular la fecha de entrega.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_2: 'El formato de la fecha es aaaa-mm-dd.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_3:
      'Si Delivery_Frequency es 7 o WEEKLY, este campo es opcional.',
    INSTRUCTIONS_ITEM_REFERENCE_DATE_4:
      'Si este campo no se completa, no se realizará ninguna modificación en este parámetro.',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY: 'Delivery_Frequency',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY_1: 'La frecuencia de entrega para cada cliente.',
    INSTRUCTIONS_ITEM_DELIVERY_FREQUENCY_2: 'Ingrese 7 o WEEKLY, 14 o BIWEEKLY, 28 o MONTHLY.',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT: 'Reset_To_DDC_Default',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT_1: 'Ingrese YES o NO.',
    INSTRUCTIONS_ITEM_RESET_TO_DDC_DEFAULT_2:
      'Si es YES, deje Minimum_Order_Value, Additional_Fee y días de la semana en blanco.',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT: 'Click_And_Collect',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT_1: 'Permite la recolección de pedidos en el sitio.',
    INSTRUCTIONS_ITEM_CLICK_AND_COLLECT_2: 'Ingrese YES o NO.',
    TEMPLATE_DOWNLOAD_BUTTON: 'Descargar Plantilla',
    UPLOAD_HINT:
      'Arrastre y suelte un archivo desde su computadora o súbalo.\nSolo admitimos archivos CSV. Tamaño máximo del archivo: 50MB.',
    UPLOAD_BUTTON: 'Subir',
  },
  ClientUploadGuidelines: {
    LIST_TITLE: 'Reglas para una carga exitosa:',
    LIST_ITEM_1: 'El punto y coma es el separador de columnas',
    LIST_ITEM_2: 'El punto es el separador decimal.',
    LIST_ITEM_3: 'Tamaño máximo del archivo: 50MB',
    LIST_ITEM_4:
      'No elimine ninguna columna ni cambie el orden de las columnas en el archivo de plantilla.',
    LIST_ITEM_5: 'Al guardar el archivo, seleccione la opción "Valores Separados por Comas (CSV)"',
    LIST_ITEM_6:
      'Si preparó el archivo a partir de un informe de exportación del cliente de Delivery Admin, elimine la columna "excepción" antes de importar el archivo.',
    LIST_ITEM_7:
      'Recomendamos formatear las columnas de identificación del cliente como texto para que Excel no elimine los ceros iniciales.',
    LIST_ITEM_8:
      'Los campos BaselineDate, DeliveryFrequency y ReturnToDDCDefault no son obligatorios; importarlos en blanco no realizará ninguna actualización de estos parámetros en la base de datos.',
    LIST_ITEM_9: 'Formato de fecha: aaaa-mm-dd',
    LIST_ITEM_10: 'El campo DeliveryFrequency solo acepta los valores: 7, 14 o 28.',
    LIST_ITEM_11:
      'Si el campo ReturnToDDCDefault se completa con SÍ, entonces las columnas de días de la semana y valores mínimos de pedido y tarifa deben dejarse en blanco.',
  },
  RuleUploadGuidelines: {
    LIST_TITLE: 'Reglas para una carga exitosa:',
    LIST_ITEM_1: 'El punto y coma es el separador de columnas',
    LIST_ITEM_2: 'El punto es el separador decimal.',
    LIST_ITEM_3: 'Tamaño máximo del archivo: 50MB',
    LIST_ITEM_4:
      'No elimine ninguna columna ni cambie el orden de las columnas en el archivo de plantilla.',
    LIST_ITEM_5: 'Al guardar el archivo, seleccione la opción "Valores Separados por Comas (CSV)"',
    LIST_ITEM_6: 'Formato de fecha: dd/mm/yyyy',
    LIST_ITEM_7: 'Formato de hora: hh:mm',
    LIST_ITEM_8:
      'Recomendamos formatear las columnas de ID del cliente como texto para que Excel no elimine los ceros a la izquierda.',
  },
  ClientEdit: {
    TITLE: 'Editar clientes',
    BREADCRUMB: 'Editar clientes',
    SUBMIT_BUTTON: 'Confirmar parámetros',
    SUBTITLE: 'Edición de ventanas de entrega',
    FIELD_EXCEPTION_LABEL: 'Tipo',
    FIELD_EXCEPTION_VALUE_EXCEPTION: 'Excepción',
    FIELD_EXCEPTION_VALUE_DDC_DEFAULT: 'Valor predeterminado de DDC',
    FIELD_MINVALUE_LABEL: 'Pedido mínimo',
    FIELD_ADDAMMOUNT_LABEL: 'Tarifa',
    FIELD_HAS_DELIVERY_LABEL: 'Tiene entrega',
    FIELD_DELIVERY_TYPE_LABEL: 'Tipo de entrega',
    FIELD_DELIVERY_TYPE_VALUE_EMPTY: 'No hay entrega',
    FIELD_DELIVERY_TYPE_VALUE_FLEX: 'Flexible',
    FIELD_DELIVERY_TYPE_VALUE_FREE: 'Gratis',
    FIELD_MONEY_ERROR: 'El valor debe ser mayor que cero',
    FIELD_DELIVERY_FREQUENCY_LABEL: 'Frecuencia de entrega',
    FIELD_DELIVERY_FREQUENCY_7: 'Cada semana',
    FIELD_DELIVERY_FREQUENCY_14: 'Cada 2 semanas',
    FIELD_DELIVERY_FREQUENCY_28: 'Cada 4 semanas',
    CLIENTS_LIST_TITLE: 'Clientes seleccionados',
    CLIENTS_LIST_VIEW_MORE: 'Ver más',
    CLIENTS_LIST_HIDE: 'Ocultar',
    CLICK_AND_COLLECT_ENABLED: 'Click & Collect Activado',
    VISIT_DATE: 'Día de la Visita:',
    NO_VISIT_DATE: 'Sin día de visita',
    BASELINE_DATE: 'Fecha base:',
    BASELINE_DATE_TOOLTIP:
      'La fecha base se utiliza como referencia para calcular las ventanas de entrega de frecuencia quincenal y mensual.',
    BASELINE_DATE_FORMAT: 'dd/MM/yyyy',
  },
  ClientEditModalConfirm: {
    TITLE: '¿Confirmar cambios en las ventanas entrega para estos Clientes?',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT:
      'Al guardar, se volverán a calcular las ventanas de entrega. ¿Confirmar los cambios?',
  },
  ClientPatchModalConfirm: {
    TITLE: '¿Confirmar cambios en Click & Collect para estos clientes?',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT: 'Al guardar, los cambios de Click & Collect se guardarán. ¿Confirmar los cambios?',
  },
  ClientEditResponseMessage: {
    SUCCESS: 'Clientes actualizados con éxito',
    NOT_FOUND_CLIENTS: 'Clientes no encontrados:',
    DUPLICATED_CLIENTS: 'Clientes duplicados:',
    INVALID_MINVALUE_OR_ADDAMOUNT_CLIENTS: 'Valores de pedido mínimo y/o tarifa no válidos',
    DEFAULT_ERROR: 'Error al guardar datos',
  },
  ClientRules: {
    TITLE: 'Editar reglas',
    BREADCRUMB: 'Editar reglas de inclusión/exclusión',
    OWNER_LIST_TITLE: 'Clientes seleccionados',
    OWNER_LIST_VIEW_MORE: 'Ver más',
    OWNER_LIST_HIDE: 'Ocultar',
    SUBMIT_BUTTON: 'Confirmar parámetros',
  },
  RulesModalConfirm: {
    TITLE: '¿Confirmar los cambios en las reglas de inclusión/exclusión para estos clientes?',
    CONFIRM_BUTTON: 'Confirmar',
    CANCEL_BUTTON: 'Cancelar',
    MAIN_TEXT:
      'Esta acción no se puede deshacer y los feriados se aplicarán a las ventanas de entrega. ¿Confirmar los cambios?',
  },
  RulesForm: {
    STATUS_EDITING: 'Regla editada',
    STATUS_DELETING: 'Regla marcada para exclusión',
    STATUS_INSERTING: 'Nueva regla',
    STATUS_PRISTINE: 'Regla guardada',
    TABLE_CELL_STATUS: 'Estatus',
    TABLE_CELL_NAME: 'Nombre',
    TABLE_CELL_TYPE: 'Clientes de la Unidad',
    TABLE_CELL_RULE_TYPE: 'Tipo de excepción',
    TABLE_CELL_DATE: 'Fecha',
    TABLE_CELL_EXPIRATION: 'Fecha de expiración',
    TABLE_CELL_FLEXIBLE: 'Tipo de ventana',
    TABLE_CELL_MINVALUE: 'Pedido mínimo',
    TABLE_CELL_ADDAMOUNT: 'Tarifa',
    TABLE_CELL_ACTIONS: 'Acciones',
    TABLE_NO_DATA: 'No se encontraron reglas con estos filtros',
    RULE_TYPE_INCLUSION: 'Inclusión',
    RULE_TYPE_EXCLUSION: 'Exclusión',
    DELETE_LABEL: 'Marcar para eliminar',
    DELETE_LABEL_UNDO: 'Desmarcar para eliminar',
    DELETE_LABEL_NEW: 'Eliminar nuevo',
    INVALID_DATE_MESSAGE: 'Fecha invalida',
    FIELD_DATE_FORMAT: 'dd/MM/yyyy',
    FIELD_EXPIRATION_FORMAT: 'dd/MM/yyyy HH:mm',
    FIELD_EXPIRATION_EXPIRED: 'La fecha seleccionada ya venció',
    FIELD_EXPIRATION_DDC: 'Usando la expiración predeterminada de DDC',
    FIELD_DELIVERY_TYPE_VALUE_FLEX: 'Flexible',
    FIELD_DELIVERY_TYPE_VALUE_FREE: 'Gratis',
    FIELD_TYPE_ALL_DDC_CLIENTS: 'Todos',
    FIELD_TYPE_ALL_DDC_FLEXIBLE_CLIENTS: 'Solo Flex',
    FIELD_TYPE_ALL_DDC_FREE_CLIENTS: 'Solo Gratis',
    FIELD_TYPE_ALL_DDC_CLIENTES_WITHOUT_DELIVERY: 'Sin Entrega',
    ADD_NEW_TITLE: 'Agregar regla de inclusión/exclusión',
    ADD_NEW_FIELD_ddc: 'Unidades',
    ADD_NEW_FIELD_ddc_ALL: 'Seleccionar todas las unidades',
    ADD_NEW_FIELD_client: 'Clientes',
    ADD_NEW_FIELD_client_ALL: 'Seleccionar todos los clientes',
    ADD_NEW_DATE_FORMAT: 'dd/MM/yyyy',
    ADD_NEW_FIELD_DATE: 'Fecha',
    ADD_NEW_SUBMIT: 'Agregar',
    FILTER_TITLE: 'Buscar:',
    FILTER_DATE_FORMAT: 'dd/MM/yyyy',
    FILTER_START_DATE: 'Fecha de inicio',
    FILTER_END_DATE: 'Fecha final',
    FILTER_SUBMIT: 'Buscar',
    UNABLE_TO_LOAD_LIST: 'Error al obtener la lista de reglas',
    UNABLE_TO_SAVE_RULES: 'Error al guardar la lista de reglas',
    SAVING_ERROR_WARNING_1:
      'No se pudieron guardar una o más reglas. Ver detalles pasando el cursor sobre el “',
    SAVING_ERROR_WARNING_2: '” icono en las líneas resaltadas',
    SAVING_ERROR_INVALIDTIMEZONE: 'Unidad sin zona horaria',
    SAVING_ERROR_LOCALEXPIRATIONDATE: 'Fecha de expiracion inválida',
    SAVING_ERROR_FLEXIBLE: 'Tipo de ventana inválido',
    SAVING_ERROR_ALREADYEXISTS: 'La regla ya existe',
    SAVING_ERROR_CLIENTNOTFOUND: 'Cliente no encontrado',
    SAVING_ERROR_DDCNOTFOUND: 'Unidad no encontrada',
    SAVING_ERROR_RULENOTFOUND: 'Regla no encontrada',
    SAVING_ERROR_DUPLICATEDPARAMS: 'Regla duplicada',
    SAVING_ERROR_EARLIERDATE: 'La regla es anterior a hoy',
    ADD_ERROR_DUPLICATED: 'No se agregaron una o más reglas porque están duplicadas',
    ADD_ERROR_VALIDATIONFAILED: 'Error de validación de datos',
  },
  ComplexFilter: {
    BUTTON_CLEAR_ALL: 'Borrar todo',
    BUTTON_CANCEL: 'Cancelar',
    BUTTON_APPLY: 'Aplicar',
    BUTTON_OPEN_FILTER: 'Filtrar por',
    DDCS_TITLE: 'DCs',
    DDCS_SEARCH_PLACEHOLDER: 'Buscar por código o nombre de DC',
    AUTOBLOCK_SITUATION_TITLE: 'Assessment',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.allGood}`]: 'Normal',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.warning}`]: 'Necesita atención',
    [`AUTOBLOCK_SITUATION_${AutoblockSituationFilterOptions.blocked}`]: 'Bloqueado',
    AUTOBLOCK_CAPACITY_REACHED_TITLE: 'Capacidad alcanzada (%)',
    AUTOBLOCK_CAPACITY_REACHED_MIN: 'Mínimo',
    AUTOBLOCK_CAPACITY_REACHED_MAX: 'Máximo',
    PERIOD_TITLE: 'Período',
    PERIOD_START: 'Fecha de inicio',
    PERIOD_END: 'Fecha de finalización',
    PERIOD_TAG: 'Desde el {start} hasta el {end}',
    PERIOD_DATEFORMAT: 'dd-MMM-yyyy',
    IMPORTS_EXPORTS_STATUS_TITLE: 'Estado',
  },
  Autoblock: {
    STRATEGIES: 'Estrategias',
    CAPACITY_IN: 'Capacidad en {value}%',
    CAPACITY_OVER: 'Capacidad excedente a {value}%',
    SUBGROUP: 'Subgrupo de PoCs: {value}',
    INCREASED_BY: 'Aumentado en {value}%',
    [`STRATEGY_TYPE_${StrategyType.Block}`]: 'Bloquear la ventana de entrega',
    [`STRATEGY_TYPE_${StrategyType.IncreaseAddAmount}`]: 'Aumentar tarifa',
    [`STRATEGY_TYPE_${StrategyType.IncreaseMinValue}`]: 'Aumentar MOV',
    [`STRATEGY_TYPE_${StrategyType.TransformToFlex}`]: 'Cambiar entrega a flexible',
    [`STRATEGY_TYPE_${StrategyType.TransformToFree}`]: 'Cambiar entrega a gratuita',
    [`STRATEGY_SUBGROUP_${DdcGroup.All}`]: 'Todos',
    [`STRATEGY_SUBGROUP_${DdcGroup.Flex}`]: 'Solo flexible',
    [`STRATEGY_SUBGROUP_${DdcGroup.Free}`]: 'Solo gratis',
    [`STRATEGY_SUBGROUP_${DdcGroup.NoDelivery}`]: 'Sin entrega',
  },
  AutoblockProgress: {
    TABTITLE: 'Progreso de estrategias',
    BREADCRUMB: 'Parámetros de disponibilidad de capacidad de entrega',
    TITLE: 'Parámetros de disponibilidad de capacidad de entrega',
    DATAGRID_COLUMN_DDC_NAME: 'Centro de distribucion',
    DATAGRID_COLUMN_LAST_UPDATED: 'Última actualización',
    DATAGRID_COLUMN_STRATEGY_COUNT: 'Estrategias activas',
    DATAGRID_COLUMN_ASSESSMENT: 'Evaluación',
    DATEFORMAT: 'dd-MMM-yyyy',
    LAST_UPDATED_TEXT: '{date} por {user}',
    UNABLE_TO_LOAD_DETAILS: 'Erro al cargar los detalles.',
    DETAILS_DATEFORMAT: 'eeee, dd-MMM-yyyy',
    ACTIVATION_DATETIMEFORMAT: 'EEE, dd-MMM-yyyy, HH:mm',
    DETAILS_CARD_OBSERVATION_TITLE: 'Capacidad',
    DETAILS_CARD_OBSERVATION:
      'Solo mostrar tarjetas para los días en que la capacidad alcanzada es superior al 5%.',
    DETAILS_CARD_CREATED_BY: 'Creado por',
    DETAILS_CARD_CAPACITY_REACHED: '{visits} de {visitLimit} visitas alcanzadas',
    DETAILS_ACTIVE_STRATEGY: 'Estrategia activa',
    DETAILS_VIEW_DETAILS: 'Mostrar detalles',
    DETAILS_CARD_LOAD_MORE: 'Cargar más',
    DETAILS_PLANNED_STRATEGIES: 'Estrategias planificadas',
  },
  AutoblockCreate: {
    TITLE: 'Agregar un parámetro de disponibilidad',
    BREADCRUMB: 'Agregar un parámetro de disponibilidad',
    DDCS_TITLE: 'Centros de distribución',
    DDCS_TEXT:
      'Seleccione los centros de distribución donde se aplicará este parámetro de disponibilidad.',
    PERIOD_TITLE: 'Periodo',
    PERIODO_TEXTO:
      'Puedes seleccionar uno o varios días de la semana, o definir una fecha específica para aplicar el parámetro de disponibilidad.',
    RECURRENCE_LABEL: 'Recurrencia',
    RADIO_DAYS_OF_WEEK_LABEL: 'Día(s) de la semana',
    RADIO_SPECIFIC_DATE_LABEL: 'Fecha específica',
    SPECIFIC_DATE_LABEL: 'Fecha',
    DAYS_OF_WEEK_LABEL: 'Día(s) de la semana',
    SPECIFIC_DATE_FORMAT: 'dd/MM/yyyy',
    LIMIT_TITLE: 'Número límite de visitas de DC',
    LIMIT_TEXT: 'Si se alcanza el límite, la ventana de entrega se bloqueará.',
    LIMIT_INPUT_PLACEHOLDER: 'Ingresa el número de límite',
    CHANNELS_LABEL: 'Canales de pedido',
    CHANNELS_PLACEHOLDER: 'Seleccione uno o más canales',
    CHANNELS_TEXT:
      'Puede seleccionar uno o más canales de pedido que se considerarán en el cálculo del consumo de capacidad.',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesCustomerApp}`]: 'BEES Customer App',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesCustomerWeb}`]: 'BEES Customer Web',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesForce}`]: 'BEES Force',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesGrow}`]: 'BEES Grow',
    [`CHANNELS_OPTION_${AutoblockChannels.BeesLink}`]: 'BEES Link',
    [`CHANNELS_OPTION_${AutoblockChannels.NonBees}`]: 'Pedidos que no son BEES',
    [`CHANNELS_OPTION_${AutoblockChannels.Other}`]: 'Otros canales',
    STRATEGIES_TITLE: 'Estrategias',
    STRATEGIES_TEXT: 'Definir estrategias a implementar según la capacidad del CD.',
    STRATEGIES_DC_CAPACITY_LABEL: 'Capacidad del CD (%)',
    STRATEGIES_TYPE_LABEL: 'Estrategia',
    STRATEGIES_SUBGROUP_LABEL: 'Aplicar a PoCs',
    STRATEGIES_VALUE_INCREASE_LABEL: 'Aumentar importe (%)',
    STRATEGIES_ADD_BUTTON: 'Agregar estrategia',
    LEAVE_MODAL_TITLE: '¿abandonar los modificaciones?',
    LEAVE_MODAL_TEXT: 'Perderás toda la información proporcionada.',
    LEAVE_MODAL_CONFIRM: 'Sí, cancelar',
    LEAVE_MODAL_CANCEL: 'No, volver atrás',
    CONFIRM_BUTTON: 'Guardar',
    CONFIRM_MODAL_TITLE: '¿Guardar parámetro de disponibilidad?',
    CONFIRM_MODAL_TEXT:
      'Las estrategias se implementarán de acuerdo a la capacidad del CD, en la siguiente secuencia.',
    TOAST_SUCCESS: 'Parámetro de disponibilidad guardado con éxito',
    TOAST_FAILURE: 'Error al crear Parámetro de disponibilidad. Vea los detalles abajo.',
    TOAST_MIXED:
      'No se pudieron crear uno o más Parámetro de disponibilidad. Vea los detalles abajo.',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.validationFailed}`]:
      'CD {ddcCode}: ocurrió un error al guardar el parámetro de disponibilidad de capacidad',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.notFound}`]:
      'CD {ddcCode}: no se encontró el parámetro de disponibilidad de capacidad',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.earlierDate}`]:
      'CD {ddcCode}: no es posible registrar un parámetro de disponibilidad de capacidad para una fecha anterior a hoy',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.alreadyExists}`]:
      'CD {ddcCode}: ya se ha registrado un parámetro de disponibilidad de capacidad para este día',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.ddcNotFound}`]:
      'CD {ddcCode}: el centro de distribución no existe o no está activo en el Delivery Admin',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.maxStrategiesReached}`]:
      'CD {ddcCode}: se pueden agregar un máximo de 10 estrategias por parámetro de disponibilidad de capacidad',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidPercentage}`]:
      'CD {ddcCode}: el porcentaje informado en la estrategia es inválido',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.duplicatedPercentage}`]:
      'CD {ddcCode}: hay más de una estrategia con el mismo porcentaje',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.mandatoryIncreaseValue}`]:
      'CD {ddcCode}: es obligatorio llenar el campo del porcentaje de aumento para las estrategias de aumento de FEE o MOV',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidIncreaseValue}`]:
      'CD {ddcCode}: el porcentaje informado en la estrategia de aumento de FEE o MOV es inválido',
    [`ERROR_TYPE_${AddAutoblockErrorCodes.invalidSubgroup}`]:
      'CD {ddcCode}: uno o más subgrupos seleccionados en las estrategias son inválidos',
    SUCCESS_MESSAGE: 'CD {ddcCode}: parámetro de disponibilidad de capacidad añadido con éxito',
    TOAST_ERROR_LOADING_AUTOBLOCK: 'Error al cargar el parámetro de disponibilidad',
  },
  errorNoVendor: {
    BREADCRUMB: 'Acceso denegado',
    DESCRIPTION:
      'Parece que no tiene ningún vendorId asociado con su usuario. Abra un ticket para que el equipo de soporte solicite acceso al ID del proveedor que necesita editar.',
    HEADER: 'Hmm... Acceso denegado!',
  },
};

export default es419;
